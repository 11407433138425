import { Empty, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getData } from "../../Actions";
import TableHeader from "../../Components/TableHeader";

const Gasoil = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [dates, setDates] = useState([moment().startOf("week"), moment().endOf("week")]);
	const [type, setType] = useState("day");

	const colors = ["#E6194B", "#3CB44B", "#FFE119", "#4363D8", "#F58231", "#911EB4", "#46F0F0", "#F032E6", "#BCF60C", "#FABEBE"];

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const groupBy = type === "minute" ? "yyyy-MM-dd HH:mm:00" : type === "hour" ? "yyyy-MM-dd HH:00:00" : "yyyy-MM-dd";
			const query = `
                SELECT
                    name,
                    FORMAT(date, '${groupBy}') AS date,
                    MAX(value) AS value
                FROM
                    Bionorica
                WHERE
                    date BETWEEN '${dates[0].format("YYYY-MM-DD HH:00:00")}' AND '${dates[1].format("YYYY-MM-DD HH:00:00")}'
                GROUP BY
                    name,
                    FORMAT(date, '${groupBy}')
                ORDER BY date;
            `;
			try {
				const result = await getData(query);
				const processedData = processData(result);
				setData(processedData);
			} catch (error) {
				message.error("Error al cargar los datos");
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [dates, type]);

	const processData = (rawData) => {
		const dataMap = {};
		rawData.forEach((item) => {
			if (!dataMap[item.date]) {
				dataMap[item.date] = { date: item.date };
			}
			dataMap[item.date][item.name] = parseFloat(item.value);
		});
		return Object.values(dataMap);
	};

	const refresh = () => {
		setData([]);
	};

	const changeData = (newDates) => {
		setDates([newDates[0].startOf(type), newDates[1].endOf(type)]);
	};

	const getDataKeys = () => {
		if (data.length === 0) return [];
		const keys = Object.keys(data[0]).filter((key) => key !== "date");
		return keys;
	};

	return (
		<div>
			<TableHeader loading={loading} dates={dates} changeData={changeData} refresh={refresh} type={type} setType={setType} />
			<div style={{ width: "90%", height: 400, margin: "auto", marginTop: 20 }}>
				{data.length !== 0 ? (
					<ResponsiveContainer width="100%" height="100%">
						<BarChart data={data}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="date" />
							<YAxis />
							<Tooltip />
							<Legend />
							{getDataKeys().map((key, index) => (
								<Bar key={key} dataKey={key} fill={colors[index % colors.length]} />
							))}
						</BarChart>
					</ResponsiveContainer>
				) : (
					<Empty description="No hay datos" />
				)}
			</div>
		</div>
	);
};

export default Gasoil;
