import styled from "styled-components";

export const PageHeader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Title = styled.h1`
	text-align: center;
	margin: 20px 0;
`;

export const Card = styled.div`
	margin: 20px;
	padding: 15px;
	border: 1px solid #f0f0f0;
	border-radius: 8px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

export const CardTitle = styled.h3`
	margin-bottom: 10px;
`;

export const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 15px;
	font-size: 0.9em;
`;

export const GridItem = styled.div`
	margin-bottom: ${(props) => (props.noMargin ? "0" : "10px")};
`;

export const Label = styled.div`
	color: #666;
`;

export const Value = styled.div`
	font-size: 1.1em;
	font-weight: bold;
`;
