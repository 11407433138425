import React, { useState } from "react";

const Home = () => {
	const [url, setUrl] = useState("");

	const handleInputChange = (e) => {
		setUrl(e.target.value);
	};

	const handleRedirect = () => {
		window.location.href = url;
	};

	return (
		<div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
			<div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
				<h1>Inicio</h1>
				<div>
					<input type="text" value={url} onChange={handleInputChange} />
					<button onClick={handleRedirect}>Go</button>
				</div>
				<a href="/Gasoil">Gasoil</a>
				<a href="/CalaMurada">Cala Murada</a>
				<a href="/Informe">Informe SAM</a>
			</div>
		</div>
	);
};

export default Home;
