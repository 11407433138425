import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Gasoil from "./Pages/Bionorica/Gasoil";
import CalaMurada from "./Pages/SAM/CalaMurada";
import Informe from "./Pages/SAM/Informe";
import Home from "./Pages/Home";
import { ConfigProvider } from "antd";
import es_ES from "antd/es/locale/es_ES";

function App() {
	return (
		<BrowserRouter>
			<ConfigProvider locale={es_ES}>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/Gasoil" element={<Gasoil />} />
					<Route path="/CalaMurada" element={<CalaMurada />} />
					<Route path="/Informe" element={<Informe />} />
				</Routes>
			</ConfigProvider>
		</BrowserRouter>
	);
}

export default App;
