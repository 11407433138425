import { message, Table, Button } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getData } from "../../Actions";
import TableHeader from "../../Components/TableHeader";
import { DownloadOutlined } from "@ant-design/icons";

const CALAMurada = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [dates, setDates] = useState([moment("2024-05-01").startOf("week"), moment("2024-05-01").endOf("week")]);
	const [type, setType] = useState("week");

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const groupBy = type === "minute" ? "yyyy-MM-dd HH:mm:00" : type === "hour" ? "yyyy-MM-dd HH:00:00" : "yyyy-MM-dd";
			const query = `
                SELECT
                    name,
                    FORMAT(date, '${groupBy}') AS date,
                    MAX(value) AS value
                FROM
                    SAMManacor
                WHERE
                    date BETWEEN '${dates[0].format("YYYY-MM-DD HH:00:00")}' AND '${dates[1].format("YYYY-MM-DD HH:00:00")}'
                    AND name IN ('Maniobres bomba gran', 'Maniobres bomba petita', 'Bomba gran - Estat', 'Bomba petita - Estat', 
                    'Bomba gran - Avaria', 'Bomba petita - Avaria', 'Bomba gran - selector', 'Bomba petita - selector',
                    'Nivell cobertura GSM', 'Ordre a bomba gran', 'Ordre a bomba petita', 'Equip en linia', 'Nivell virtual',
                    'Nivell dip petit', 'Nivell dip gran', 'Ordre rebuda per SMS', 'Mode remot B gran', 'Mode remot B petita',
                    'HTC a bomba gran', 'HTC a bomba petita')
                GROUP BY
                    name,
                    FORMAT(date, '${groupBy}')
                ORDER BY date;
            `;
			try {
				const result = await getData(query);
				setData(result);
			} catch (error) {
				message.error("Error al cargar los datos");
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [dates, type]);

	const refresh = () => {
		setData([]);
	};

	const changeData = (newDates) => {
		setDates([newDates[0].startOf(type), newDates[1].endOf(type)]);
	};

	const columns = [
		{
			title: "Fecha",
			dataIndex: "date",
			key: "date",
		},
		{
			title: "Nombre",
			dataIndex: "name",
			key: "name",
			filters: Array.from(new Set(data.map((item) => item.name))).map((name) => ({
				text: name,
				value: name,
			})),
			onFilter: (value, record) => record.name === value,
		},
		{
			title: "Valor",
			dataIndex: "value",
			key: "value",
			render: (value) => value && Number(value).toFixed(2),
		},
	];

	const handleExport = () => {
		try {
			const headers = ["Fecha", "Nombre", "Valor"];
			const csvContent = [headers.join(","), ...data.map((row) => [row.date, row.name, row.value].join(","))].join("\n");

			const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
			const link = document.createElement("a");
			const url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", `cala_murada_${dates[0].format("YYYY-MM-DD")}_${dates[1].format("YYYY-MM-DD")}.csv`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {
			message.error("Error al exportar los datos");
		}
	};

	return (
		<div>
			<TableHeader loading={loading} dates={dates} changeData={changeData} refresh={refresh} type={type} setType={setType} />
			<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "20px 0" }}>
				<h1 style={{ margin: 0, marginLeft: "20px" }}>Histórico ZEUS Cala Murada</h1>
				<Button
					type="primary"
					style={{ marginRight: "20px", backgroundColor: "#000", borderColor: "#000" }}
					icon={<DownloadOutlined />}
					onClick={handleExport}
					disabled={loading || data.length === 0}
				>
					Exportar
				</Button>
			</div>
			<Table dataSource={data} columns={columns} virtual />
		</div>
	);
};

export default CALAMurada;
