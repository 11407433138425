import { message, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getData } from "../../Actions";
import DatePicker from "../../Components/DatePicker";
import { Card, CardTitle, GridContainer, GridItem, Label, PageHeader, Title, Value } from "./styles";

const CALAMurada = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [date, setDate] = useState(moment().startOf("day"));

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const query = `
				WITH DailyMaxValues AS (
					SELECT 
						name,
						FORMAT(date, 'yyyy-MM-dd') AS date,
						MAX(value) AS max_value
					FROM SAMManacor
					WHERE date BETWEEN '${date.clone().subtract(1, "day").startOf("day").format("YYYY-MM-DD HH:mm:00")}' 
						AND '${date.clone().endOf("day").format("YYYY-MM-DD HH:mm:00")}'
						AND name IN (
							'CabalAcumulatSortidaSaMoladora',
							'CabalAcumulatSortidaEsPollenci',
							'CabalAcumulatSortidaSesTapereres',
							'CabalAcumulatSortidaSaGravera',
							'CabalAcumulatSortidaSaPlana'
						)
					GROUP BY name, FORMAT(date, 'yyyy-MM-dd')
				)
				SELECT name, date, max_value as value
				FROM DailyMaxValues
				ORDER BY name, date;
			`;
			try {
				const result = await getData(query);
				setData(result);
			} catch (error) {
				message.error("Error al cargar los datos");
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [date]);

	const renderValue = (name) => {
		const today = date.format("YYYY-MM-DD");
		const yesterday = date.clone().subtract(1, "day").format("YYYY-MM-DD");

		const todayValue = data.find((d) => d.name === name && d.date === today)?.value || 0;
		const yesterdayValue = data.find((d) => d.name === name && d.date === yesterday)?.value || 0;

		const difference = todayValue - yesterdayValue;

		return difference !== 0 ? (
			<Value>
				<span style={{ marginRight: "5px" }}>
					{Number(difference).toLocaleString("es-ES", {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					})}
				</span>
				m³
			</Value>
		) : (
			"-"
		);
	};

	const calculateTotal = () => {
		const names = ["CabalAcumulatSortidaSaMoladora", "CabalAcumulatSortidaEsPollenci", "CabalAcumulatSortidaSesTapereres", "CabalAcumulatSortidaSaGravera"];

		const total = names.reduce((sum, name) => {
			const today = date.format("YYYY-MM-DD");
			const yesterday = date.clone().subtract(1, "day").format("YYYY-MM-DD");

			const todayValue = data.find((d) => d.name === name && d.date === today)?.value || 0;
			const yesterdayValue = data.find((d) => d.name === name && d.date === yesterday)?.value || 0;

			return sum + (todayValue - yesterdayValue);
		}, 0);

		return total !== 0 ? (
			<Value>
				<span style={{ marginRight: "5px", fontWeight: "bold" }}>
					{Number(total).toLocaleString("es-ES", {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					})}
				</span>
				m³
			</Value>
		) : (
			"-"
		);
	};

	return (
		<Spin spinning={loading}>
			<div>
				<PageHeader>
					<Title>Informe SAM</Title>
					<DatePicker style={{ marginLeft: "20px" }} value={date} onChange={setDate} />
				</PageHeader>
				<Card>
					<CardTitle>Bombeig - Volum bombejat (m³)</CardTitle>
					<GridContainer>
						<div>
							<GridItem>
								<Label>Sa Moladora</Label>
								{renderValue("CabalAcumulatSortidaSaMoladora")}
							</GridItem>
							<GridItem noMargin>
								<Label>Es Pollenci</Label>
								{renderValue("CabalAcumulatSortidaEsPollenci")}
							</GridItem>
						</div>
						<div>
							<GridItem>
								<Label>Ses Tapereres</Label>
								{renderValue("CabalAcumulatSortidaSesTapereres")}
							</GridItem>
							<GridItem noMargin>
								<Label>Sa Gravera</Label>
								{renderValue("CabalAcumulatSortidaSaGravera")}
							</GridItem>
						</div>
						<div style={{ width: "100%" }}>
							<GridItem>
								<Label>Sa Plana</Label>
								{renderValue("CabalAcumulatSortidaSaPlana")}
							</GridItem>
						</div>
					</GridContainer>
					<GridItem style={{ marginTop: "20px", borderTop: "1px solid #eee", paddingTop: "20px" }}>
						<Label>Total</Label>
						{calculateTotal()}
					</GridItem>
				</Card>
			</div>
		</Spin>
	);
};

export default CALAMurada;
